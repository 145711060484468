import React, {Fragment} from 'react';
import Heading from "../uiStyle/Heading";
import TrendingNewsSlider from "../TrendingNewsSlider";
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

import transm1 from '../../doc/img/trending/transm1.jpg';
import transm2 from '../../doc/img/trending/transm2.jpg';
import transm4 from '../../doc/img/trending/transm4.jpg';
import transm5 from '../../doc/img/trending/transm5.jpg';
import transm6 from '../../doc/img/trending/transm6.jpg';

const trendingNews = [
    {
        image: 'https://placebear.com/200/154',
        title: 'Trending News - 1',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: 'https://placeimg.com/200/154/any',
        title: 'Trending News - 2',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: 'https://picsum.photos/200/154',
        title: 'Trending News - 3',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: 'https://placebeard.it/200x154',
        title: 'Trending News - 4',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: 'https://placekitten.com/200/154',
        title: 'Trending News - 5',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    }/*,
    {
        image: transm4,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'TrendingNews - 6'
    }*/
];

const TrendingNews = ({dark}) => {
    return (
        <Fragment>
            <Heading title="Trending News"/>
            <TrendingNewsSlider/>
            {dark ? <div className="border_white"/> : <div className="border_black"/>}
            <div className="space-30"/>
            <div className="row">
                <div className="col-lg-6">
                    {trendingNews.slice(0, 3).map((item, i) => (
                        <Fragment key={i}>
                            <div className="single_post widgets_small">
                                <div className="post_img">
                                    <div className="img_wrap">
                                        <img src={item.image} alt="thumb"/>
                                    </div>
                                    <span className="trending">
									<FontAwesome name="bolt"/>
								</span>
                                </div>
                                <div className="single_post_text">
                                    <div className="meta2">
                                        <Link to="/">{item.category}</Link>
                                        <Link to="/">{item.date}</Link>
                                    </div>
                                    <h4><Link to="/post1">{item.title}</Link></h4>
                                </div>
                            </div>
                            <div className="space-15"/>
                            {dark ? <div className="border_white"/> : <div className="border_black"/>}
                            <div className="space-15"/>
                        </Fragment>
                    ))}
                </div>
                <div className="col-lg-6">
                    {trendingNews.slice(3, 6).map((item, i) => (
                        <Fragment key={i}>
                            <div className="single_post widgets_small">
                                <div className="post_img">
                                    <div className="img_wrap">
                                        <img src={item.image} alt="thumb"/>
                                    </div>
                                    <span className="trending">
									<FontAwesome name="bolt"/>
								</span>
                                </div>
                                <div className="single_post_text">
                                    <div className="meta2">
                                        <Link to="/">{item.category}</Link>
                                        <Link to="/">{item.date}</Link>
                                    </div>
                                    <h4><Link to="/post1">{item.title}</Link></h4>
                                </div>
                            </div>
                            <div className="space-15"/>
                            {dark ? <div className="border_white"/> : <div className="border_black"/>}
                            <div className="space-15"/>
                        </Fragment>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default TrendingNews;