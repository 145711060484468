export function getCurrentDate(separator=' '){

    let newDate = new Date()
    let date = newDate.getDate();
    let monthInt = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let numberDay = newDate.getDay() + 1;


    var d = new Date();
    var weekday = new Array(8);
    weekday[1] = "Sunday";
    weekday[2] = "Monday";
    weekday[3] = "Tuesday";
    weekday[4] = "Wednesday";
    weekday[5] = "Thursday";
    weekday[6] = "Friday";
    weekday[7] = "Saturday";

    var m = new Date();
    var month = new Array(13);
    month[1] = "January";
    month[2] = "February";
    month[3] = "March";
    month[4] = "April";
    month[5] = "May";
    month[6] = "June";
    month[7] = "July";
    month[8] = "August";
    month[9] = "September";
    month[10] = "October";
    month[11] = "November";
    month[12] = "December";

    return `${weekday[numberDay]} ${month[monthInt]} ${numberDay} , ${newDate.getFullYear()}`
    
    //return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }